<template>
    <v-col class="co-card-plano-anual justify-center" cols="10" lg="2" md="3" sm="4" xs="1">
        <div v-if="!(ehMelhorOpcao || plano.promocao)" class="d-flex justify-center melhor-opcao"/>

        <v-card class="card-principal text-center mx-2">
            <div v-if="plano.promocao" class="melhor-opcao-promocao">
                <div class="py-2"><strong>DESCONTOS EXCLUSIVOS</strong></div>
                <v-divider/>
            </div>

            <div v-else-if="ehMelhorOpcao" class="melhor-opcao">
                <div class="py-2"><strong>MELHOR OPÇÃO</strong></div>
                <v-divider/>
            </div>

            <v-card-text class="card-principal-conteudo text-color-default">
                <div :style="'color: primary'" class="justify-center titulo mb-5">
                    {{ plano.titulo.toUpperCase() }}
                </div>

                <div v-if="plano.descricao" class="subtitulo descricao">
                    <div v-for="descricao in getDescricao" :key="descricao">
                        {{ descricao }}
                    </div>
                </div>

                <v-tabs
                    v-if="plano.valorParcela > 0"
                    v-model="tabSelecionado"
                    fixed-tabs
                    color="primary"
                    class="tabs-wrapper"
                    slider-color="primary"
                >
                    <v-tab v-for="tipo in tiposTabs" :key="tipo.titulo" :value="tipo">
                        <div class="tabs">
                            {{ tipo.titulo }}
                            {{ tipo.subtitulo }}
                        </div>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabSelecionado">
                    <v-tab-item>
                        <plano-precos
                            :ehParcela="false"
                            :valorComparacaoMensal="valorComparativoMensalArredondado(plano.valorPacote)"
                            :valorTotal="plano.valorPacote"
                        />
                    </v-tab-item>

                    <v-tab-item>
                        <plano-precos
                            :ehParcela="true"
                            :valorComparacaoMensal="valorComparativoMensalArredondado(4 * plano.valorParcela)"
                            :valorTotal="plano.valorParcela"
                        />
                    </v-tab-item>
                </v-tabs-items>

                <div class="text-left beneficios">
                    <div v-for="beneficios in plano.beneficios" :key="beneficios.beneficio">
                        <span class="text-color-default">
                            <v-icon v-if="beneficios.destaque === undefined || beneficios.destaque" color="green" size="15px">
                                mdi-check-circle-outline
                            </v-icon>

                            {{ beneficios.beneficio }}
                        </span>
                    </div>
                </div>

                <div class="validade">
                    <span class="text-color-default validade-texto">{{ plano.validade }}</span>
                </div>
            </v-card-text>

            <v-card-actions>
                <div class="adquirir">
                    <v-btn v-if="ehPlanoAtual" class="botao meu-plano" block disabled>
                        MEU PLANO
                        <v-icon size="18" right> mdi-check </v-icon>
                    </v-btn>

                    <v-btn v-else-if="ehAlteracaoPlano" class="botao" :disabled="!ehAdministradorOrganizacao" block @click="fazerAlteracaoPlano">
                        Assinar
                    </v-btn>

                    <v-btn v-else class="botao" color="primary" :disabled="!ehAdministradorOrganizacao" block dark @click="contratarPlano">
                        Assinar
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>

        <div v-if="!ehPlanoEmCancelamento && ehAdministradorOrganizacao" class="d-flex justify-center cancelar-plano">
            <span v-if="ehPlanoAtual" class="my-3 text-color-default btn-cancelar" @click="cancelarPlano">
                CANCELAR PLANO
            </span>
        </div>
    </v-col>
</template>

<script>
import PlanoPrecos from '../../plano-precos/PlanoPrecos'
import {actionTypes} from '@/commons/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'CardPlanoAnual',
    components: {
        PlanoPrecos,
    },
    props: {
        plano: {
            type: Object,
            required: true
        },
        ehPlanoAtual: {
            type: Boolean,
            default: false
        },
        ehMelhorOpcao: {
            type: Boolean,
            default: false
        },
        ehAlteracaoPlano: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            tiposTabs: [
                {
                    titulo: 'Anual',
                    subtitulo: '[20%OFF]'
                }, {
                    titulo: 'Parcelado',
                    subtitulo: '[15%OFF]'
                }
            ],
            tabSelecionado: 'Anual',
        }
    },
    computed: {
        ...mapGetters(['quantidadeParcelasMaximas', 'ehPlanoEmCancelamento', 'ehAdministradorOrganizacao']),
        getDescricao() {
            return this.plano.descricao.split(';')
        }
    },
    methods: {
        cancelarPlano() {
            this.$emit('cancelar-plano')
        },
        async contratarPlano() {
            const ehParcelaSelecionada = this.tiposTabs[this.tabSelecionado].titulo === 'Parcelado'

            this.plano.parcelas = ehParcelaSelecionada ? this.quantidadeParcelasMaximas : 0
            this.$emit('abrir-pagamentos', this.plano)

            await this.enviarEmailComercial()
        },
        async enviarEmailComercial() {
            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': `Lead ${this.$store.state.loki.user.fullName}, do email: ${this.$store.state.login.organizacaoPlano.organizacaoEmail}, do telefone: ${this.$store.state.login.organizacaoPlano.organizacaoTelefone ?? 'N/A'}, da organização: ${this.$store.state.login.organizacaoPlano.organizacaoNome}, atualmente no plano '${this.$store.getters.nomePlano}' clicou em 'Assinar' no plano '${this.plano.titulo}'`,
                'assunto': `Lead ${this.$store.state.loki.user.fullName} clicou em Assinar - Plano ${this.plano.titulo}`,
                'tituloEmail': `Lead ${this.$store.state.loki.user.fullName} clicou em Assinar - Plano ${this.plano.titulo}`
            })
        },
        async fazerAlteracaoPlano() {
            this.$emit('alterar-plano')

            await this.enviarEmailComercial()
        },
        valorComparativoMensalArredondado(valor) {
            return Math.floor(100 * valor / 12) / 100
        }
    }
}
</script>

<style lang="stylus">
.co-card-plano-anual
    min-width 290px

    .text-color-default
        color var(--text-color-200)

    .melhor-opcao
        height 37px

    .card-principal
        border-radius 5px

        .tabs-wrapper
            margin-bottom 8px
            margin-top 8px

            .v-tabs-bar__content
                width  90%
                height 32px
                background-color var(--tabs-100)
                border-radius 5px

            .tabs
                font-size 11px

        .melhor-opcao
            background-color var(--v-primary-base)
            color white

        .melhor-opcao-promocao
            background-color var(--primary)
            color white

        .card-principal-conteudo
            .titulo
                font-weight bold
                font-size 17px
                color var(--primary)
                height 43px

            .subtitulo
                font-size 15px

            .descricao
                min-height 66px
                margin-bottom 12px
                color var(--text-color-200)

            .beneficios
                font-size 13px
                height 185px

            .meu-plano
                color var(--primary) !important

            .validade-texto
                font-size 13px

    .adquirir
        width 100%

    .cancelar-plano
        min-height 45px

        .btn-cancelar
            cursor pointer

@media (min-width: 1840px)
    .co-card-plano-anual-api
        .card-principal
            .card-principal-conteudo
                .beneficios
                    height 190px

@media (max-height: 840px)
    .co-card-plano-anual
        .card-principal
            .card-principal-conteudo
                .beneficios
                    display none
</style>
