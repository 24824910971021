<template>
    <v-dialog v-model="dialog" persistent width="800" scrollable :fullscreen="ehMobile">
        <v-card class="co-documento-dialog-visualizar-pdf">
            <v-card-title class="pa-0">
                <v-toolbar flat dark color="primary">
                    <span class="headline titulo">{{ nome }}</span>
                    <v-spacer></v-spacer>
                    <v-icon @click="fechar"> mdi-window-close </v-icon>
                </v-toolbar>
            </v-card-title>

            <v-card-text class="pa-0">
                <az-pdf-document-viewer
                    class="visualizador"
                    progress-bar
                    :src="pdf.base64"
                    :http-header="getHttpHeader"
                    ref="pdfDocumentViewer"
                    default-scale-type="auto"
                    height="500"
                />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'DialogVisualizarPdf',
    props: ['pdf', 'dialog'],
    data: () => {
        return {
            path: ''
        }
    },
    computed: {
        ...mapState(['login']),
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        getHttpHeader() {
            return {'authorization': this.login.authorization.token}
        },
        nome() {
            return this.pdf.name
        }
    },
    methods: {
        fechar() {
            this.$emit('fechar')
        }
    }
}
</script>

<style lang="stylus">
.co-documento-dialog-visualizar-pdf
    height 85vh

    .visualizador
        .az-pdf-toolbar
            height 50px !important

        .v-btn::before
            color transparent

        .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
            background-color #f5f5f500 !important

    .titulo
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
</style>
