var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co-documento-lista-upload-novo"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mb-2"},[_vm._v("Documentos ("+_vm._s(_vm.quantidadePdf)+")")]),_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.tamanhoTotalPdfs)+" de 20MB")])]),_c('v-simple-table',{class:_vm.listaUploadClass,attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_c('h3',[_vm._v("Anexo")])]),_c('th',{staticClass:"vertical-lines column-tamanho text-center"},[_c('h3',[_vm._v("Tamanho")])]),_c('th',{staticClass:"column-acoes text-center"},[_c('h3',[_vm._v("Ações")])])])]),_c('tbody',_vm._l((_vm.pdfs),function(pdf,i){return _c('tr',{key:pdf.name + i},[_c('td',{staticClass:"text-left"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.nomePdf(pdf.name))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(pdf.name))])])],1),_c('td',{staticClass:"vertical-lines text-right"},[_vm._v(_vm._s(_vm.formatBytes(pdf.size)))]),_c('td',{staticClass:"text-center pa-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"acoes-btn",on:{"click":function($event){return _vm.visualizarPdf(pdf)}}},on),[_vm._v(" mdi-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"acoes-btn",on:{"click":function($event){return _vm.abrirDialogConfirmacaoRemocao(pdf)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)])}),0)]},proxy:true}])}),(_vm.dialogConfirmacaoRemocao)?_c('dialog-default-confirmar-acao',{attrs:{"dialog":_vm.dialogConfirmacaoRemocao,"sentimento":"assustado","titulo":"Eita! Deixa eu confirmar uma coisa...","subtitulo":"Você quer mesmo remover este pdf ?","label-botao-principal":"Sim, eu quero remover!"},on:{"acao-principal":_vm.removerPdf,"fechar":_vm.fecharDialogConfirmacaoRemocao}}):_vm._e(),_c('dialog-visualizar-pdf',{attrs:{"dialog":_vm.dialogVisualizarPdf,"pdf":_vm.pdf},on:{"fechar":_vm.fecharDialogVisualizarPdf}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }