import {hasPermissions} from '@azinformatica/loki'

const rotasRedirecionamento = {
    MEUS_CONTRATOS: 'meus',
    PLANO_EXPIRADO: 'planoExpirado',
    PRIMEIRO_ACESSO: 'primeiroAcesso',
    USUARIO_INATIVO: 'usuarioInativo',
    PLANOS: 'planos'
}

export default (router, store) => {
    router.beforeEach((to, from, next) => {
        if (ehUsuarioInativo(store)) {
            redirecionarParaUsuarioInativo(next)

        } else if (semAutorizacaoParaRota(to, store)) {
            redirecionarParaMeusContratos(next)

        } else if (ehPrimeiroAcesso(store)) {
            redirecionarParaPrimeiroAcesso(to, from, next, store)

        } else if (organizacaoComPlanoExpirado(store)) {
            redirecionarParaPlanoExpirado(next)

        } else if (usuarioVisualizador(store) && ehRotaOrigemBarra(from)) {
            redirecionarParaPlanos(next)

        } else {
            continuarNavegacao(next)
        }
    })
}

function continuarNavegacao(next) {
    next()
}

function ehEfcazCLM(store) {
    return store.state.loki.product.efcazCLM
}

function ehPrimeiroAcesso(store) {
    return !store.state.login.organizacaoPlano.organizacaoExistente
}

function ehRotaDestinoMeus(to) {
    return to.name === rotasRedirecionamento.MEUS_CONTRATOS
}

function ehRotaOrigemBarra(from) {
    return from.fullPath === '/'
}

function ehUsuarioInativo(store) {
    return store.state.login.usuarioOrganizacao.usuarioSituacao === 'INATIVO'
}

function organizacaoComPlanoExpirado(store) {
    return !store.state.login.organizacaoPlano.organizacaoAtiva
}

function redirecionarPara(nomeRota, next) {
    next({name: nomeRota})
}

function redirecionarParaMeusContratos(next) {
    redirecionarPara(rotasRedirecionamento.MEUS_CONTRATOS, next)
}

function redirecionarParaPrimeiroAcesso(to, from, next, store) {
    if (ehEfcazCLM(store)) {
        redirecionarParaPrimeiroAcessoCLM(next)
    } else if (!ehRotaDestinoMeus(to)) {
        redirecionarParaPrimeiroAcessoBesign(next)
    } else {
        continuarNavegacao(next)
    }
}

function redirecionarParaPrimeiroAcessoBesign(next) {
    redirecionarPara(rotasRedirecionamento.MEUS_CONTRATOS, next)
}

function redirecionarParaPrimeiroAcessoCLM(next) {
    redirecionarPara(rotasRedirecionamento.PRIMEIRO_ACESSO, next)
}

function redirecionarParaPlanoExpirado(next) {
    redirecionarPara(rotasRedirecionamento.PLANO_EXPIRADO, next)
}

function redirecionarParaPlanos(next) {
    redirecionarPara(rotasRedirecionamento.PLANOS, next)
}

function redirecionarParaUsuarioInativo(next) {
    redirecionarPara(rotasRedirecionamento.USUARIO_INATIVO, next)
}

function usuarioVisualizador(store) {
    return store.state.login.usuarioOrganizacao.perfil === 'Visualizador'
}

function semAutorizacaoParaRota(to, store) {
    const permissoesRequeridas = to.meta && to.meta.authorities ? to.meta.authorities : []
    const permissoesUsuario = store.state.loki.user.authorities
    return !hasPermissions(permissoesUsuario, permissoesRequeridas)
}
