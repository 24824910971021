<template>
    <div>
        <v-dialog value="true" persistent fullscreen hide-overlay scrollable>
            <v-card>
                <v-card-title class="pa-0">
                    <v-col class="pa-0">
                        <toobar
                            :titulo="getTitulo"
                            :menu-itens="menuItens" possui-opcoes
                            @voltar="voltarParaMeusDocumentos"
                            @mais-opcoes="abrirDialogMaisOpcoes"
                            @deletar="deletarDocumento"
                        />
                        <toolbar-signatarios v-if="ehMobile" :mostrarTela="mostrarTela"/>
                    </v-col>
                </v-card-title>

                <v-card-text class="pa-0">
                    <v-container fluid class="co-novo-documento-signatario">
                        <v-row no-gutters ma-0 style="height: 100%">
                            <v-col class="formulario-documento pa-0" cols="12" sm="5" md="5" lg="4"
                                   v-if="mudarTelaSignatarios">

                                <formulario-signatario
                                    v-if="ehPreencherSignatarios"
                                    :documentos-signatario="documentosSignatario"
                                    :habilitar-salvar="habilitarSalvar"
                                    :papeis-signatario="papeisSignatario"
                                    :signatario-opcao="signatarioOpcao"
                                    :signatarios-dados="signatariosDados"
                                    :tipos-assinatura="tiposAssinatura"
                                    @adicionar-signatario="adicionarNovoSignatario"
                                    @continuar="continuarProximaEtapa"
                                    @remover-signatario="removerSignatario"
                                    @salvar-once="salvarOnce"
                                    @valida-tipo-assinatura="validaTipoAssinatura"
                                />

                                <posicionamento-assinatura v-else
                                    :signatarios="signatariosDados"
                                    :papeis-signatario="papeisSignatario"
                                    :loading-salvar="!habilitarSalvar"
                                    @criar-posicionamento-assinatura="criarPosicionamentoAssinatura"
                                    @salvar-once="salvarOnce"
                                    @voltar="voltarEtapa"
                                />

                            </v-col>
                            <v-col cols="12" sm="7" md="7" lg="8" v-if="mudarTelaDocumentos">
                                <div>
                                    <az-pdf-document-viewer
                                        ref="azPdfDocumentViewer"
                                        progress-bar
                                        default-scale-type="auto"
                                        :height="getHeightPdf"
                                        :draggables="draggables"
                                        :initial-draggable-width="210"
                                        :initial-draggable-height="70"
                                        draggable-link-tooltip="Repetir posicionamento para outras páginas"
                                        draggable-unlink-tooltip="Desvincular este posicionamento do restante repetido"
                                        @create:draggable="criarDraggable"
                                        @update:draggable="atualizarDraggable"
                                        @delete:draggable="deletarDraggable"
                                        @link:draggable="vincularDraggable"
                                        @unlink:draggable="desvincularDraggable"
                                        :http-header="getHttpHeader"
                                        :src="pathDocumento"
                                    >

                                        <template v-slot:draggable-content="{ draggable }">
                                            <v-container
                                                fill-height
                                                align-center
                                                justify-center
                                                text-xs-center
                                            >
                                                <div class="d-flex slot-posicionamento-assinatura">
                                                    <div>
                                                        <v-icon left color="primary">
                                                            {{ draggable ? getTipoPosicionamentoIcon(draggable) : '' }}
                                                        </v-icon>
                                                        {{ draggable.content.typeName }}
                                                    </div>

                                                    <div>
                                                        <span class="posicionamento-assinatura-email">
                                                            {{ draggable.content.text }}
                                                        </span>
                                                    </div>
                                                </div>

                                            </v-container>
                                        </template>

                                    </az-pdf-document-viewer>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-info-signatario-whatsapp-assinando
            :mostrarDialog="dialogSignatarioWhatsAppAssinando.mostrarOutros || dialogSignatarioWhatsAppAssinando.mostrarPriorizar"
            :signatarios-impedidos="getSignatariosImpedidosWhatsApp"
            :priorizar="dialogSignatarioWhatsAppAssinando.mostrarPriorizar"
            :mostrar-outros="dialogSignatarioWhatsAppAssinando.mostrarOutros"
            @alterar-priorizar="alterarPriorizar"
            @fechar="fecharDialogSignatarioWhatsAppAssinando"
        />

        <dialog-falha-signatario-whatsapp
            :mostrarDialog="dialogFalhaSignatarioWhatsapp"
            @fechar="fecharDialogFalhaSignatarioWhatsapp"
        />

        <dialog-default-confirmar-acao
            v-if="dialogConfirmacaoPular"
            :dialog="dialogConfirmacaoPular"
            sentimento="assustado"
            titulo="Eita! Deixa eu confirmar uma coisa..."
            subtitulo="Seus dados não foram validados, você quer mesmo ir a tela de gestão do documento?<br> Seus dados serão perdidos!"
            label-botao-principal="Sim, eu quero ir!"
            @acao-principal="confirmarPular"
            @fechar="fecharDialogConfirmacaoPular"
        />

        <dialog-repetir-posicionamento
            :dialog="dialogRepetirPosicionamento"
            @fechar-dialog="fecharDialogRepetirPosicionamento"
            @repetir-posicionamento="vincularPosicionamentoOutrasPaginas"
        />

        <dialog-default-confirmar-acao
            v-if="dialogdefaultConfirmaAcao"
            :dialog="dialogdefaultConfirmaAcao"
            sentimento="assustado"
            titulo="Saldo WhatsApp insuficiente"
            :subtitulo="subtitulo"
            label-botao-principal="Sim, eu quero!"
            @acao-principal="acaoDialogDefaultConfirmaAcao"
            @fechar="fecharDialogDefaultConfirmarAcao"
        />
    </div>
</template>

<script>
import Toobar from '@/views/criar-documento/components/toobar/Toobar'
import actionTypes from '@/commons/constants/action-types'
import ToolbarSignatarios from '@/views/criar-documento/components/toobar/ToolbarSignatarios'
import DialogInfoSignatarioWhatsappAssinando
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/dialogs/DialogInfoSignatarioWhatsappAsinando'
import DialogFalhaSignatarioWhatsapp
    from '@/views/visualizar-contrato/barra-lateral/assinaturas/components/dialogs/DialogFalhaSignatarioWhatsapp'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {mutationTypes} from '@/commons/constants'
import store from '@/commons/store'
import DialogDefaultConfirmarAcao from '@/commons/components/dialogs/DialogDefaultConfirmarAcao'
import FormularioSignatario from '@/views/criar-documento/novo-documento-signatario/components/FormularioSignatario'
import PosicionamentoAssinatura from './components/posicionamento-assinatura/PosicionamentoAssinatura'
import DialogRepetirPosicionamento from '@/commons/components/dialogs/DialogRepetirPosicionamento'
import RouterUtils from '@/commons/utils/RouterUtils'

export default {
    name: 'SignatarioNovoDocumento',
    components: {
        DialogRepetirPosicionamento,
        PosicionamentoAssinatura,
        FormularioSignatario,
        ToolbarSignatarios,
        Toobar,
        DialogInfoSignatarioWhatsappAssinando,
        DialogFalhaSignatarioWhatsapp,
        DialogDefaultConfirmarAcao
    },
    data() {
        return {
            draggables: [],
            draggableToLink: {},
            signatarioPosicionamentoAssinaturaAtual: {
                email: '',
                type: ''
            },
            ehPreencherSignatarios: true,
            habilitarSalvar: true,
            pathDocumento: '',
            mostrarTela: {signatarios: true},
            documentosSignatario: [],
            signatariosDados: [],
            signatarioOpcao: {
                documentoId: 1,
                assinaturaManuscrita: true,
                chaveSeguranca: '',
                mensagem: '',
                rejeitarDocumento: true,
                ordemAssinaturaDefinida: false,
            },
            papeisSignatario: [],
            dialogMaisOpcoes: false,
            dialogSignatarioWhatsAppAssinando: {
                mostrarPriorizar: false,
                mostrarOutros: false
            },
            dialogFalhaSignatarioWhatsapp: false,
            dialogConfirmacaoPular: false,
            dialogRepetirPosicionamento: false,
            signatariosImpedidos: {
                daOrganizacao: [],
                outros: []
            },
            documentoId: '',
            menuItens: [{
                title: `Remover ${this.$tc('pt_br.documento')}`,
                event: 'deletar'
            }],
            tiposAssinatura: [
                {id: 'ELETRONICA', nome: 'Assinatura Eletrônica (E-mail)'},
                {id: 'DIGITAL', nome: 'Assinatura Digital'},
                //{id: 'WHATSAPP', nome: 'Assinatura Whatsapp', disabled: false, saldo: 0}
            ],
            tipoAssinaturaDefault: 'ELETRONICA',
            dialogdefaultConfirmaAcao: false,
        }
    },
    created() {
        this.possuiAssinaturaDigital()
        this.carregarTipoSignatarioDefault()
    },
    async mounted() {
        this.documentoId = this.$route.params.id

        await this.carregarPapeisSignatarios()
        await this.carregarDocumentosSignatario()
        await this.buscarConfiguracoesSignatarios()

        try {
            this.setCarregandoDadosContrato(true)

            await this.buscarContratoPorId()
            await this.buscarVersaoMaisRecenteMinuta()
            await this.buscarConsumoAtualUsuario()

            this.buscarDocumento()
            this.setCarregandoDadosContrato(false)
            this.adicionarNovoSignatario()
        } catch (e) {
            this.setCarregandoDadosContrato(false)
            this.$router.push({name: 'meus'})
        }
    },
    computed: {
        ...mapState(['minutaAtual', 'login', 'signatarios']),
        ...mapGetters(['caminhoVisualizarMinuta', 'ehEfcazCLM', 'ehPlanoFree', 'saldoWhatsapp', 'podeRepetirSignatarios']),
        assinaturaDigital() {
            return this.login.funcionalidadesOrganizacao.assinaturaDigital
        },
        ehMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
        ehSignatariosImpedidosDaOrganizacao() {
            return this.signatariosImpedidos.daOrganizacao && this.signatariosImpedidos.daOrganizacao.length > 0
        },
        ehSignatariosImpedidosOutros() {
            return this.signatariosImpedidos.outros && this.signatariosImpedidos.outros.length > 0
        },
        getHeightPdf() {
            return window.innerHeight - 50 + 'px'
        },
        getHttpHeader() {
            return {'authorization': this.login.authorization.token}
        },
        getSignatariosImpedidosWhatsApp() {
            return this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar ? this.signatariosImpedidos.daOrganizacao : this.signatariosImpedidos.outros
        },
        getTitulo() {
            return `Novo ${this.$tc("pt_br.documento")}`
        },
        mudarTelaSignatarios() {
            if (this.ehMobile) {
                return this.mostrarTela.signatarios
            }
            return true
        },
        mudarTelaDocumentos() {
            if (this.ehMobile) {
                return !this.mostrarTela.signatarios
            }
            return true
        },
        subtitulo() {
            return `Adquira mais saldos WhatsApp para continuar adicionando ${this.$tc("pt_br.signatario", 2).toLowerCase()}`
        }
    },
    beforeRouteLeave(to, from, next) {
        this.setMinutaAtual({})
        next()
    },
    methods: {
        ...mapActions([
            actionTypes.BUSCAR_CONTRATO,
            actionTypes.BUSCAR_MINUTA_ATUAL,
            actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO
        ]),
        ...mapMutations([
            mutationTypes.SET_CARREGANDO_DADOS_CONTRATO,
            mutationTypes.SET_MINUTA_ATUAL
        ]),
        alterarPriorizar() {
            this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar = !this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar
        },
        abrirDialogMaisOpcoes() {
            this.dialogMaisOpcoes = true
        },
        abrirDialogRepetirPosicionamento() {
            this.dialogRepetirPosicionamento = true
        },
        async acaoDialogDefaultConfirmaAcao() {
            this.dialogdefaultConfirmaAcao = false
            await this.$store.dispatch(actionTypes.EMAIL.ENVIAR_EMAIL_COMERCIAL, {
                'mensagem': `O usuário ${this.$store.state.login.usuarioOrganizacao.usuarioNome} de e-mail: ${this.$store.state.login.usuarioOrganizacao.usuarioEmail} e telefone: ${this.$store.state.login.usuarioOrganizacao.usuarioTelefone} da organização ${this.$store.state.organizacao.nome}`
                    + ` deseja comprar saldo WhatsApp para continuar adicionando ${this.$tc("pt_br.signatario", 2).toLowerCase()}`
            })
            
            this.$router.push({name: 'planos'})
        },
        adicionarNovoSignatario() {
            let possuiSaldoWhatsApp = this.validaTipoAssinatura()
            if (this.tipoAssinaturaDefault === 'WHATSAPP' && !possuiSaldoWhatsApp) {
                this.tipoAssinaturaDefault = 'ELETRONICA'
                this.dialogdefaultConfirmaAcao = true
            }
            this.signatariosDados.unshift({
                telefone: '',
                ddi: '55',
                skipFotoWpp: true,
                tipoAssinatura: this.tipoAssinaturaDefault,
                email: '',
                papelId: 16,
                enviarConviteSMS: false,
                enviarTokenSMS: false,
                representanteLegal: true
            })
        },
        atualizarDraggable({draggable, draggableIndex}) {
            Object.assign(this.draggables[draggableIndex], draggable)
        },
        async buscarConfiguracoesSignatarios() {
            if (this.ehEfcazCLM || !this.ehPlanoFree) {
                this.signatarioOpcao.chaveSeguranca = store.state.login.organizacaoConfiguracao.geral.signatario.chaveSeguranca
                this.signatarioOpcao.mensagem = store.state.login.organizacaoConfiguracao.geral.signatario.mensagem
            }
        },
        async buscarContratoPorId() {
            this.setMensagemLoading('Carregando dados do contrato')
            await this.buscarContrato(this.documentoId)
        },
        buscarDocumento() {
            this.pathDocumento = this.caminhoVisualizarMinuta
        },
        async buscarConsumoAtualUsuario() {
            await this.buscarConsumoOrganizacao()

            this.tiposAssinatura.filter(
                tipo => {
                    if (tipo.id === 'WHATSAPP') {
                        tipo.saldo = this.saldoWhatsapp
                    }
                }
            )
        },
        async buscarVersaoMaisRecenteMinuta() {
            this.setMensagemLoading('Carregando dados da minuta')
            await this.buscarMinutaAtual(this.documentoId)
        },
        async carregarDocumentosSignatario() {
            const documentos = await this.$store.dispatch(actionTypes.BUSCAR_DOCUMENTOS_SIGNATARIO)
            for (const documento of documentos) {
                this.documentosSignatario.push({
                    text: documento.nome,
                    value: documento.documentoId,
                    descricao: documento.descricao
                })
            }
        },
        async carregarPapeisSignatarios() {
            const listaPapeisSignatarios = await this.$store.dispatch(actionTypes.BUSCAR_PAPEIS_SIGNATARIOS)
            for (const papel of listaPapeisSignatarios) {
                this.papeisSignatario.push({text: papel.nome, value: papel.id})
            }
        },
        carregarTipoSignatarioDefault() {
            let tipoDefault = this.$route.params.tipoDefault
            if (tipoDefault === 'whatsApp') {
                this.tipoAssinaturaDefault = 'WHATSAPP'
            } else {
                this.tipoAssinaturaDefault = 'ELETRONICA'
            }
        },
        confirmarPular() {
            this.dialogConfirmacaoPular = false
            this.irParaDetalhesDocumento()
        },
        async continuarProximaEtapa() {
            if (this.ehSignatariosVazios()) {
                this.mostrarNotificacaoErro(`Deve possuir pelo menos 1 ${this.$tc("pt_br.signatario").toLowerCase()} para prosseguir`)
            } else if (await this.$validator._base.validateAll()) {
                this.ehPreencherSignatarios = false
            }
        },
        async convidarLoteSignatarios() {
            const signatariosConvidados = await this.$store.dispatch(actionTypes.CONVIDAR_SIGNATARIOS, {
                contratoId: this.documentoId,
                minutaId: this.minutaAtual.id
            })

            if (!signatariosConvidados) {
                this.mostrarNotificacaoErro(`Não foi possível enviar os convites. Verifique os e-mails ou contate o suporte`)
                return
            }

            if (!signatariosConvidados.data.sucesso) {
                this.signatariosImpedidos.daOrganizacao = signatariosConvidados.data.signatariosImpedidosOrganizacao
                this.signatariosImpedidos.outros = signatariosConvidados.data.signatariosImpedidosOutros

                if (this.ehSignatariosImpedidosDaOrganizacao) {
                    this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar = true
                }
                if (this.ehSignatariosImpedidosOutros) {
                    this.dialogSignatarioWhatsAppAssinando.mostrarOutros = true
                }
            } else {
                this.mostrarNotificacaoSucesso(`Documento enviado aos ${this.$tc("pt_br.signatario", 2).toLowerCase()}`)
                this.irParaDetalhesDocumento()
            }
        },
        criarDraggable({draggable}) {
            draggable.content = {
                text: this.signatarioPosicionamentoAssinaturaAtual.contato,
                type: this.signatarioPosicionamentoAssinaturaAtual.type,
                typeName: this.signatarioPosicionamentoAssinaturaAtual.typeName
            }
            this.draggables.push(draggable)
        },
        criarPosicionamentoAssinatura(signatario) {
            this.signatarioPosicionamentoAssinaturaAtual.contato = this.ehWhatsapp(signatario) ? signatario.telefone : signatario.email
            this.signatarioPosicionamentoAssinaturaAtual.type = signatario.tipoPosicionamento.value
            this.signatarioPosicionamentoAssinaturaAtual.typeName = signatario.tipoPosicionamento.tipo
            this.$refs.azPdfDocumentViewer.startCreateDraggable()
        },
        async deletarDocumento() {
            try {
                await this.$store.dispatch(actionTypes.REMOVER_CONTRATO, this.documentoId)
                this.mostrarNotificacaoSucesso(`${this.$tc('pt_br.documento')} excluído.`)
                this.voltarParaMeusContratos()
            } catch (e) {
                this.mostrarNotificacaoErro(`Não foi possível excluir o ${this.$tc('pt_br.documento')}!`)
            }
        },
        deletarDraggable({draggable, draggableIndex}) {
            this.draggables.splice(draggableIndex, 1)
        },
        desvincularDraggable({draggable, draggableIndex}) {
            this.draggables[draggableIndex].groupId = null
        },
        ehSignatariosVazios() {
            let vazio = true
            this.signatariosDados.forEach(signatario => {
                if (signatario.email !== "" || signatario.telefone !== "") {
                    vazio = false
                }
            })
            return vazio
        },
        ehWhatsapp(signatario) {
            return signatario.tipoAssinatura === 'WHATSAPP'
        },
        fecharDialogDefaultConfirmarAcao() {
            this.dialogdefaultConfirmaAcao = false
        },
        fecharDialogFalhaSignatarioWhatsapp() {
            this.dialogFalhaSignatarioWhatsapp = false;
            this.irParaDetalhesDocumento()
        },
        fecharDialogSignatarioWhatsAppAssinando() {
            if (this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar) {
                this.dialogSignatarioWhatsAppAssinando.mostrarPriorizar = false
            } else {
                this.dialogSignatarioWhatsAppAssinando.mostrarOutros = false
                this.signatariosImpedidos = {
                    daOrganizacao: [],
                    outros: []
                }
            }
            this.irParaDetalhesDocumento()
        },
        fecharDialogConfirmacaoPular() {
            this.dialogConfirmacaoPular = false
        },
        fecharDialogRepetirPosicionamento() {
            this.dialogRepetirPosicionamento = false
        },
        getTipoPosicionamentoIcon(draggable) {
            switch (draggable.content.typeName) {
                case 'Assinatura':
                    return 'mdi-gesture'

                case 'Rubrica':
                    return 'mdi-pencil'

                case 'Documento':
                    return 'mdi-badge-account-horizontal'

                case 'Email':
                    return 'mdi-email'

                case 'Nome':
                    return 'mdi-account'

                case 'Data Nascimento':
                    return 'mdi-calendar-month'

                default:
                    return 'mdi-gesture'
            }
        },
        async inserirLoteSignatarios() {
            await this.$store.dispatch(actionTypes.SIGNATARIO.INSERIR_SIGNATARIOS_NOVO, {
                contratoId: this.documentoId,
                minutaId: this.minutaAtual.id,
                signatario: this.signatariosDados,
                ordem: this.signatarioOpcao.ordemAssinaturaDefinida
            })
        },
        async inserirPosicionamentoDado() {
            if (this.draggables.length > 0) {
                const reposicionarDraggables = this.draggables.map(drag => {
                    let xInteiro = drag.percentX * 100
                    let yInteiro = drag.percentY * 100

                    const x = xInteiro > 0.15 ? (xInteiro - 0.01) : (xInteiro + 0.01)
                    const y = yInteiro > 0 ? (yInteiro - 0.01) : (yInteiro + 0.01)

                    return {
                        ...drag,
                        percentX: x / 100,
                        percentY: y / 100
                    }
                })

                const dados = {posicionamentoDados: reposicionarDraggables}
                await this.$store.dispatch(actionTypes.POSICIONAMENTO_DADO.INSERIR, dados)
            }
        },
        irParaDetalhesDocumento() {
            this.$router.push({name: 'detalheContrato', params: {id: this.documentoId}})
        },
        mostrarMensagemErroRepeticao(mensagemRepeticaoAtivada, formaContato) {
            this.mostrarNotificacaoErro(`Já existe um ${this.$tc("pt_br.signatario").toLowerCase()} com esse ${formaContato} informado. ${mensagemRepeticaoAtivada}`)
        },
        possuiAssinaturaDigital() {
            if (!this.assinaturaDigital) {
                this.tiposAssinatura.splice(1, 1)
            }
        },
        possuiEmailDuplicadoInserindo() {
            const signatarios = this.signatariosDados.filter(
                signatario => {
                    if (signatario.tipoAssinatura !== 'WHATSAPP')
                        return signatario
                })
            if (signatarios.length > 1) {
                const signatariosEmail = signatarios.map(
                    signatario => {
                        return signatario.email.toLowerCase()
                    }).sort()
                for (let i = 0; i < signatariosEmail.length; i++) {
                    if (signatariosEmail[i + 1] === signatariosEmail[i]) {
                        return true
                    }
                }
            }
            return false
        },
        possuiTelefoneDuplicado() {
            const signatarios = this.signatariosDados.filter(
                signatario => {
                    if (signatario.tipoAssinatura === 'WHATSAPP')
                        return signatario
                })
            if (signatarios.length > 1) {
                const signatariosTelefone = signatarios.map(
                    signatario => {
                        return signatario.telefone.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "")
                    }).sort()
                for (let i = 0; i < signatariosTelefone.length; i++) {
                    if (signatariosTelefone[i + 1] === signatariosTelefone[i]) {
                        return true
                    }
                }
            }
            return false
        },
        possuiChaveSeguranca() {
            return (this.signatarioOpcao.chaveSeguranca === null || this.signatarioOpcao.chaveSeguranca.length === 0) ? null : this.signatarioOpcao.chaveSeguranca
        },
        possuiMensagem() {
            return (this.signatarioOpcao.mensagem === null || this.signatarioOpcao.mensagem.length === 0) ? null : this.signatarioOpcao.mensagem
        },
        async pular() {
            if (this.ehSignatariosVazios()) {
                this.irParaDetalhesDocumento()
            } else if (await this.$validator._base.validateAll()) {
                await this.salvar(true)
            } else {
                this.dialogConfirmacaoPular = true
            }
        },
        removerSignatario(signatario) {
            this.signatariosDados.splice(this.signatariosDados.indexOf(signatario), 1);
            this.validaTipoAssinatura()

            if (this.signatariosDados.length < 2) {
                this.signatarioOpcao.ordemAssinaturaDefinida = false
            }
        },
        async salvarOnce(pular) {
            this.habilitarSalvar = false
            try {
                if (pular) {
                    await this.pular()
                } else {
                    await this.salvar(false)
                }
            } catch (e) {
                const exceptionType = e.response.data.exception
                if (exceptionType && exceptionType.search('FalhaAoEnviarMensagemTextoWhatsappException') !== -1) {
                    this.dialogFalhaSignatarioWhatsapp = true
                }
                this.habilitarSalvar = true
            }
            setTimeout(() => this.habilitarSalvar = true, 2000)
        },
        async salvar(pular) {
            this.habilitarSalvar = false

            let formularioObrigatorioValido = false

            formularioObrigatorioValido = await this.$validator._base.validateAll()

            if (!formularioObrigatorioValido) {
                return
            }

            const mensagemRepeticaoHabilitada = `Para incluir um mesmo ${this.$tc("pt_br.signatario").toLowerCase()} mais de uma vez, defina uma ordem de assinatura para o ${this.$tc("pt_br.documento").toLowerCase()}, então inclua o ${this.$tc("pt_br.signatario").toLowerCase()} que precisa repetir.`
            const mensagemOrdemAssinaturaDesabilitada = ` e ative a ordem de assinatura do ${this.$tc("pt_br.documento").toLowerCase()}`
            const exibirMensagemRepeticao = this.podeRepetirSignatarios ? mensagemRepeticaoHabilitada : `Para incluir um mesmo ${this.$tc("pt_br.signatario").toLowerCase()} mais de uma vez, ative a opção para repetir ${this.$tc("pt_br.signatario", 2).toLowerCase()} nas configurações de segurança${!this.signatarioOpcao.ordemAssinaturaDefinida ? mensagemOrdemAssinaturaDesabilitada : ''}.`

            if (!this.podeRepetirSignatarios || !this.signatarioOpcao.ordemAssinaturaDefinida) {
                if (this.possuiEmailDuplicadoInserindo()) {
                    this.mostrarMensagemErroRepeticao(exibirMensagemRepeticao, 'email')
                    this.habilitarSalvar = true
                    return
                }
    
                if (this.possuiTelefoneDuplicado()) {
                    this.mostrarMensagemErroRepeticao(exibirMensagemRepeticao, 'telefone')
                    this.habilitarSalvar = true
                    return
                }
            }
            
            this.formatarSignatarios()

            try {
                await this.inserirLoteSignatarios()
                await this.inserirPosicionamentoDado()

                if (pular) {
                    this.irParaDetalhesDocumento()
                } else {
                    await this.convidarLoteSignatarios()
                }
            } catch (e) {
                const exceptionType = e.response.data.exception

                if (exceptionType && exceptionType.search('SignatarioJaAdicionadoException') !== -1) {
                    this.mostrarNotificacaoErro(`${this.$tc("pt_br.signatario")} já adicionado no ${this.$tc("pt_br.documento").toLowerCase()}`)
                } else {
                    this.mostrarNotificacaoErro(`Não foi possível salvar os ${this.$tc("pt_br.signatario", 2).toLowerCase()}. Verifique os dados ou contate o suporte`)
                }

                if (pular) {
                    this.dialogConfirmacaoPular = true
                }
            }
        },
        formatarSignatarios() {
            this.signatariosDados.forEach(signatario => {
                signatario.email = signatario.tipoAssinatura === 'WHATSAPP' ? '' : signatario.email
                signatario.telefone = signatario.tipoAssinatura === 'WHATSAPP' ? signatario.telefone
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/\s/g, "") : ''
                signatario.disponibilidadeAssinatura = 'REMOTA'
                signatario.documentoId = this.signatarioOpcao.documentoId
                signatario.assinaturaManuscrita = this.signatarioOpcao.assinaturaManuscrita
                signatario.chaveSeguranca = signatario.tipoAssinatura === 'WHATSAPP' ? null : this.possuiChaveSeguranca()
                signatario.mensagem = signatario.tipoAssinatura === 'WHATSAPP' ? null : this.possuiMensagem()
                signatario.rejeitarDocumento = this.signatarioOpcao.rejeitarDocumento
            })
        },
        validaTipoAssinatura() {
            const signatariosWhatsApp = this.signatariosDados.filter(
                signatario => {
                    if (signatario.tipoAssinatura === 'WHATSAPP')
                        return signatario
                })
            const saldoRestante = this.saldoWhatsapp - signatariosWhatsApp.length
            const possuiSaldo = saldoRestante > 0
            this.tiposAssinatura.filter(
                tipo => {
                    if (tipo.id === 'WHATSAPP') {
                        tipo.disabled = !possuiSaldo
                        tipo.saldo = saldoRestante
                    }
                }
            )
            return possuiSaldo
        },
        vincularDraggable({draggable, draggableIndex}) {
            this.draggableToLink = this.draggables[draggableIndex]
            this.signatarioPosicionamentoAssinaturaAtual.contato = this.draggableToLink.content.text
            this.signatarioPosicionamentoAssinaturaAtual.type = this.draggableToLink.content.type
            this.abrirDialogRepetirPosicionamento()
        },
        vincularPosicionamentoOutrasPaginas(repetirPosicionamento) {
            this.fecharDialogRepetirPosicionamento()
            switch (repetirPosicionamento) {
                case 'TODAS_AS_PAGINAS':
                    return this.vincularEmTodasAsPaginas()
                case 'PROXIMA_PAGINA':
                    return this.vincularNaProximaPagina()
                case 'ULTIMA_PAGINA':
                    return this.vincularNaUltimaPagina()
            }
        },
        vincularEmTodasAsPaginas() {
            this.$refs.azPdfDocumentViewer.linkDraggablesByPageInterval(this.draggableToLink, (pagination) => ({
                startPage: 1,
                endPage: pagination.total
            }))
        },
        vincularNaProximaPagina() {
            this.$refs.azPdfDocumentViewer.linkDraggablesByPageInterval(this.draggableToLink, (pagination) => ({
                startPage: this.draggableToLink.pageNumber + 1,
                endPage: this.draggableToLink.pageNumber + 1
            }))
        },
        vincularNaUltimaPagina() {
            this.$refs.azPdfDocumentViewer.linkDraggablesByPageInterval(this.draggableToLink, (pagination) => ({
                startPage: pagination.total,
                endPage: pagination.total
            }))
        },
        voltarEtapa() {
            this.draggables = []
            this.ehPreencherSignatarios = true
        },
        voltarParaMeusDocumentos() {
            RouterUtils.voltarParaMeusDocumentos(this.meusContratosBusca.filtros.formalizacao.value)
        },
    }
}
</script>

<style lang="stylus">
.co-novo-documento-signatario
    background var(--background-200) !important

    .document-draggable-item
        &__background
            background-color rgba(255, 255, 255, 0.7) !important

        button
            i
                color white

    .formulario-documento
        height 100%
        overflow-y auto
        background-color var(--background-200)


    .v-btn:not(.v-btn--round).v-size--large
        padding 0 10px

    .slot-posicionamento-assinatura
        color var(--primary)
        flex-direction column

        .posicionamento-assinatura-email
            color var(--text-color-200)

    .documentViwer
        .az-pdf-toolbar
            height 49px !important

@media (max-width: 600px)
    .co-novo-documento-signatario
        .v-btn
            color unset

        .opcoes-avancadas
            margin-bottom 35px

        .botoes
            position absolute
            bottom 0
            width 100%
            background-color var(--background-200)

            .btn-enviar
                width 92%
                height 60px !important

            .btn-pular
                width 92%
                height 50px !important
                margin 10px 0

</style>
