import Vue from 'vue'
import _ from 'lodash'
import {mutationTypes} from '@/commons/constants'
import FileManagerUtils from '@/commons/utils/FileManagerUtils'

const SISTEMA_DEFAULT = 'Efcaz-CLM'

export default {

    [mutationTypes.COMUN.SET_CONSUMO_ORGANIZACAO](state, consumoOrganizacao) {
        state.consumoOrganizacao = consumoOrganizacao
    },

    [mutationTypes.LOGIN.SET_ORGANIZACAO_PLANO](state, organizacao) {
        state.login.organizacaoPlano = organizacao

        state.organizacao.organizacaoId = organizacao.organizacaoId
        state.organizacao.nome = organizacao.organizacaoNome
    },

    [mutationTypes.LOGIN.SET_VINCULO_USUARIO_ORGANIZACAO](state, usuarioOrganizacao) {
        state.login.usuarioOrganizacao = usuarioOrganizacao
    },

    [mutationTypes.LOGIN.SET_FUNCIONALIDADES_ORGANIZACAO](state, funcionalidadesOrganizacao) {
        state.login.funcionalidadesOrganizacao = funcionalidadesOrganizacao
    },

    [mutationTypes.LOGIN.SET_USUARIO_LOGADO](state, usuario) {
        const user = {
            id: usuario.userId,
            name: usuario.userName,
            fullName: usuario.name,
            photo: FileManagerUtils.createThumbnailUrl(usuario.imageUrl),
            type: usuario.tipoUsuario,
            domainType: usuario.domainType,
            domainId: usuario.domainId,
            authorities: usuario.authorities
        }
        Vue.set(state.loki, 'user', user)
    },

    [mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO](state, organizacaoConfiguracao) {
        state.login.organizacaoConfiguracao = organizacaoConfiguracao
    },

    [mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO_GERAL](state, organizacaoConfiguracao) {
        state.login.organizacaoConfiguracao.geral = organizacaoConfiguracao
    },

    [mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO_NOTIFICACAO](state, organizacaoConfiguracao) {
        state.login.organizacaoConfiguracao.notificacoes = organizacaoConfiguracao
    },

    [mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO_WEBHOOK](state, organizacaoConfiguracao) {
        Vue.set(state.login.organizacaoConfiguracao, 'webhook', organizacaoConfiguracao)
    },

    [mutationTypes.LOGIN.SET_AUTHORIZATION_TOKEN](state, token) {
        state.login.authorization.token = token
    },

    [mutationTypes.DECREMENTAR_PAGINA_NOTIFICACOES_BUSCA](state) {
        state.notificacoesBusca.paginacao.page = state.notificacoesBusca.paginacao.page - 1
    },

    [mutationTypes.INCREMENTAR_PAGINA_NOTIFICACOES_BUSCA](state) {
        state.notificacoesBusca.paginacao.page = state.notificacoesBusca.paginacao.page + 1
    },

    [mutationTypes.RESET_NOTIFICACOES](state) {
        state.notificacoes = {
            mensagens: [],
            totalNaoLido: 0
        }
    },

    [mutationTypes.SET_API_BUSCA_MEUS_CONTRATOS](state, api) {
        state.meusContratosBusca.api = api
    },

    [mutationTypes.SET_CARREGANDO_DADOS_CONTRATO](state, carregando) {
        state.carregandoDadosContrato = carregando
    },

    [mutationTypes.SET_CONTRATO](state, contrato) {
        state.contrato = contrato
    },

    [mutationTypes.SET_ESTADOS](state, estados) {
        Vue.set(state, 'estados', estados)
    },

    [mutationTypes.SET_MENU_AVATAR](state) {
        const actions = {
            1: {title: 'Perfil', icon: 'mdi-account', path: '/perfil'},
        }
        Vue.set(state.loki, 'avatarActions', actions)
    },

    [mutationTypes.SET_MEUS_CONTRATOS](state, contratos) {
        Vue.set(state, 'meusContratos', contratos)
    },

    [mutationTypes.SET_MINUTA_ATUAL](state, minuta) {
        state.minutaAtual = minuta
    },

    [mutationTypes.SET_NOTIFICACOES](state, notificacoes) {
        state.notificacoes.mensagens = state.notificacoes.mensagens.concat(notificacoes.content)
        state.notificacoes.totalElementos = notificacoes.totalElements
        state.notificacoes.totalNaoLido = notificacoes.totalNaoLido
    },

    [mutationTypes.SET_NOTIFICACOES_MENSAGENS](state, mensagens) {
        state.notificacoes.mensagens = mensagens
    },

    [mutationTypes.SET_HISTORICO_CONTRATO](state, historico) {
        Vue.set(state, 'historicoContrato', historico)
    },

    [mutationTypes.SET_PAGINACAO_BUSCA_CONTRATOS](state, paginacao) {
        state.paginacaoContratos = paginacao
    },

    [mutationTypes.SET_PAGINACAO_BUSCA_NOTIFICACOES](state, paginacao) {
        Vue.set(state.notificacoesBusca, 'paginacao', paginacao)
    },

    [mutationTypes.SET_PARAMETROS_SISTEMA](state, parametros) {
        state.parametrosSistema = parametros
    },

    [mutationTypes.SET_ORGANIZACAO](state, organizacao) {
        state.organizacao = organizacao
    },

    [mutationTypes.SET_COOKIES](state, cookiesNovos) {
        const cookies = state.cookies

        cookies.filtros = cookiesNovos.filtros
        cookies.promoCloseCount = cookiesNovos.promoCloseCount
        Vue.set(state, 'cookies', cookies)
    },

    [mutationTypes.SET_PRODUTO](state, {data, packageJson}) {
        state.loki.product.id = data.id
        state.loki.product.name = data.nome
        state.loki.product.mainLogo = FileManagerUtils.createUrl(data.atributosExtendidos.logoMenu)
        state.loki.product.mainLogoBranca = FileManagerUtils.createUrl(data.atributosExtendidos.logoMenuBranca)
        state.loki.product.symbolLogo = FileManagerUtils.createUrl(data.atributosExtendidos.logoMenuRetraido)
        state.loki.product.logoMobile = FileManagerUtils.createUrl(data.atributosExtendidos.logoMobile)
        state.loki.product.version = packageJson.version
        state.loki.product.copywrite = 'Todos os direitos reservados'
        state.loki.product.logoutUrl = '/hal/logout'
        state.loki.product.logoPlanoDefault = FileManagerUtils.createUrl(data.atributosExtendidos.logoPlanoDefault)
        state.loki.product.termoUsoEfcazCLM = FileManagerUtils.createUrl(data.atributosExtendidos.termoUsoEfcazCLM)
        state.loki.product.efcazCLM = data.atributosExtendidos.loginAreaInterno.includes(SISTEMA_DEFAULT)
    },

    [mutationTypes.SET_SIGNATARIOS](state, signatarios) {
        Vue.set(state, 'signatarios', signatarios)
    },

    [mutationTypes.SET_TOTAL_CONTRATOS_LISTAGEM](state, totalContratosListagem) {
        Vue.set(state.meusContratosBusca, 'totalContratosListagem', totalContratosListagem)
    },

    [mutationTypes.SET_MOBILE](state, value) {
        state.mobile = value
    },

    [mutationTypes.SET_PAGINACAO_CADASTROS_SIGNATARIOS](state, paginacao) {
        Vue.set(state.cadastrosSignatariosBusca, 'paginacao', paginacao)
    },

    [mutationTypes.SET_FILTROS_CADASTROS_SIGNATARIOS](state, filtros) {
        Vue.set(state.cadastrosSignatariosBusca, 'filtros', filtros)
    },

    [mutationTypes.ACOES.SET_ACOES_FILTRAR](state) {
        state.acaoFiltrar = !state.acaoFiltrar
    },

    [mutationTypes.ACOES.SET_ACOES_ORDENAR](state) {
        state.acaoOrdenar = !state.acaoOrdenar
    },

    [mutationTypes.SET_PAGINACAO_GRUPOS_SIGNATARIOS](state, paginacao) {
        Vue.set(state.gruposSignatariosBusca, 'paginacao', paginacao)
    },

    [mutationTypes.SET_FILTROS_GRUPOS_SIGNATARIOS](state, filtros) {
        Vue.set(state.gruposSignatariosBusca, 'filtros', filtros)
    },

    [mutationTypes.WEBHOOK.SET_PAGINACAO_WEBHOOKS](state, paginacao) {
        Vue.set(state.webhookPendentesBusca, 'paginacao', paginacao)
    },

    [mutationTypes.FEEDBACK.SET_PESQUISA_FEEDBACK](state, feedback) {
        Vue.set(state, 'pesquisaFeedback', _.merge(state.pesquisaFeedback, feedback))
    }

}
