import { render, staticRenderFns } from "./ListaUpload.vue?vue&type=template&id=5b899962"
import script from "./ListaUpload.vue?vue&type=script&lang=js"
export * from "./ListaUpload.vue?vue&type=script&lang=js"
import style0 from "./ListaUpload.vue?vue&type=style&index=0&id=5b899962&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports