import {buildMenu} from '@azinformatica/loki'
import moment from 'moment-timezone'
import {i18n} from './plugins/i18n'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import VueCookies from 'vue-cookies'
import packageJson from '../package'
import App from './App'
import router from './router'
import store from './commons/store'
import Keycloak from 'keycloak-js'
import {actionTypes, fusoHorario, mutationTypes} from './commons/constants'
import './commons/filters'
import './commons/mixins'
import './plugins'
import axios from 'axios'

Vue.use(VueCookies)

class AppBuilder {

    async build() {
        this._setFusoHorarioDefault()
        await this._resolverDependencias()
        this._carregarCookies()
        this._setTerminologiaLinguaguemSistema()
        this._criarInstanciaVue()
    }

    async _resolverDependencias() {
        await this._buscarProduto()
        await this._iniciarKeycloak()
        await this._buscarParametrosSistema()
        await this._buscarUsuarioLogado()
        await this._buscarOrganizacaoPlano()
        await this._buscarPreferenciasUsuario()
        if (store.state.login.organizacaoPlano.organizacaoExistente) {
            await this._buscarOrganizacaoConfiguracao()
            await this._buscarVinculoUsuarioOrganizacao()
            await this._buscarFuncionalidadesOrganizacao()
            await this._buscarConsumoOrganizacao()
            await this._buscarFeedback()
        } else {
            await this._mockarDadosTemporariosOrganizazcao()
        }
        this._atualizarAvatarActions()
    }

    _atualizarAvatarActions() {
        store.commit(mutationTypes.SET_MENU_AVATAR)
    }

    async _buscarConsumoOrganizacao() {
        await store.dispatch(actionTypes.COMUN.BUSCAR_CONSUMO_ORGANIZACAO)
    }

    async _buscarFeedback() {
        await store.dispatch(actionTypes.FEEDBACK.BUSCAR_FEEDBACK)
    }

    async _buscarFuncionalidadesOrganizacao() {
        await store.dispatch(actionTypes.LOGIN.BUSCAR_FUNCIONALIDADES_ORGANIZACAO)
    }

    async _buscarOrganizacaoConfiguracao() {
        await store.dispatch(actionTypes.CONFIGURACAO.BUSCAR_ORGANIZACAO_CONFIGURACOES)
    }

    async _buscarOrganizacaoPlano() {
        await store.dispatch(actionTypes.LOGIN.BUSCAR_ORGANIZACAO_PLANO)
    }

    async _buscarParametrosSistema() {
        await store.dispatch(actionTypes.LOGIN.BUSCAR_PARAMETROS_SISTEMA)
    }

    async _buscarPreferenciasUsuario() {
        await store.dispatch(actionTypes.USUARIO.BUSCAR_PREFERENCIAS_USUARIO)
    }

    async _buscarProduto() {
        await store.dispatch(actionTypes.LOGIN.BUSCAR_PRODUTO_POR_NOME, packageJson)
    }

    async _buscarUsuarioLogado() {
        await store.dispatch(actionTypes.LOGIN.BUSCAR_USUARIO_LOGADO)
    }

    async _buscarVinculoUsuarioOrganizacao() {
        await store.dispatch(actionTypes.LOGIN.BUSCAR_VINCULO_USUARIO_ORGANIZACAO)
    }

    _carregarCookies() {
        let cookiesRecuperados = VueCookies.get(store.getters.nomeCookieUsuario)
        if (!!cookiesRecuperados && !!cookiesRecuperados.filtros) {
            store.commit(mutationTypes.FILTROS.RECUPERAR_FILTROS_BUSCA_COOKIES, cookiesRecuperados)
            store.commit(mutationTypes.SET_COOKIES, cookiesRecuperados)
        }
    }

    _criarInstanciaVue() {
        new Vue({
            router,
            store,
            i18n,
            vuetify,
            render: (h) => h(App),
            created() {
                this.$store.commit('SET_MENU_ACTIONS', buildMenu(store, router))
            },
            errorCaptured(error) {
                if (error.message === 'Extrapolou o limite direito da página.') {
                    this.mostrarNotificacaoErro('Tente clicar mais para esquerda do documento.')
                } else if (error.message === 'Extrapolou o limite inferior da página.') {
                    this.mostrarNotificacaoErro('Tente clicar mais para cima do documento.')
                } else {
                    throw error
                }
                return false;
            }
        }).$mount('#app')
    }

    async _mockarDadosTemporariosFuncionalidades() {
        let funcionalidades = {
            assinaturaDigital: false,
            assinaturaEletronicaEmail: true,
            assinaturaEletronicaWhatsapp: true,
            enviarTokenAssinaturaSMS: true,
            notificarConviteSMS: true,
            signatarioPagante: false,
            validarAberturaEmail: true,
            itemMinuta: false
        }
        await store.commit(mutationTypes.LOGIN.SET_FUNCIONALIDADES_ORGANIZACAO, funcionalidades)
    }

    async _mockarDadosTemporariosOrganizazcao() {
        await this._mockarDadosTemporariosOrganizacaoPlano()
        await this._mockarDadosTemporariosOrganizacaoConfiguracoes()
        await this._mockarDadosTemporariosUsuario()
        await this._mockarDadosTemporariosFuncionalidades()
        await this._mockarDadosTemporariosOrganizazcaoConsumo()
    }

    async _mockarDadosTemporariosOrganizacaoConfiguracoes() {
        let configuracoes = {
            geral: {
                documento: {
                    documentosNaRaiz: true
                },
                signatario: {
                    chaveSeguranca: null,
                    mensagem: null
                }
            },
            notificacao: {
                usuario: {
                    emailUsuarioAdicional: true
                }
            }
        }
        await store.commit(mutationTypes.LOGIN.SET_ORGANIZACAO_CONFIGURACAO, configuracoes)
    }

    async _mockarDadosTemporariosOrganizazcaoConsumo() {
        let organizacaoConsumo = {
            documentosIlimitados: false,
            documentosUtilizados: 0,
            totalDocumentos: 10,

            usuariosIlimitados: true,
            usuariosUtilizados: 1,
            totalUsuarios: 1000,

            smsUtilizados: 0,
            totalSMS: 0,
            whatsappUtilizados: 0,
            totalWhatsapp: 3,
            organizacaoSemAssinatura: true
        }
        await store.commit(mutationTypes.COMUN.SET_CONSUMO_ORGANIZACAO, organizacaoConsumo)
    }

    async _mockarDadosTemporariosOrganizacaoPlano() {
        const dadosOrganizacao = await store.dispatch(actionTypes.ORGANIZACAO.BUSCAR_DADOS_ORGANIZACAO_PRIMEIRO_ACESSO)

        let organizacaoPlano = {
            organizacaoId: 0,
            organizacaoAtiva: true,
            organizacaoExistente: false,
            signatarioPagante: null,
            organizacaoNome: dadosOrganizacao.nome,
            nomePlano: "FREE",
            nomePlanoContratado: "FREE",
            dataRenovacaoDocumentos: (new Date()).toLocaleDateString('pt-BR'),
            administradoresId: [],
            aceiteTermoSignatarioPagante: null,
            dadosOrganizacao: dadosOrganizacao
        }
        await store.commit(mutationTypes.SET_ORGANIZACAO, dadosOrganizacao)
        await store.commit(mutationTypes.LOGIN.SET_ORGANIZACAO_PLANO, organizacaoPlano)
    }

    async _mockarDadosTemporariosUsuario() {
        let usuario = {
            organizacaoId: 0,
            usuarioId: store.state.loki.user.id,
            usuarioSituacao: "ATIVO",
            perfil: "Administrador da Organização"
        }
        await store.commit(mutationTypes.LOGIN.SET_VINCULO_USUARIO_ORGANIZACAO, usuario)
    }

    _setFusoHorarioDefault() {
        moment.tz.setDefault(fusoHorario.FUSO_HORARIO_DEFAULT)
        moment.locale('pt')
    }

    _setTerminologiaLinguaguemSistema() {
        store.state.loki.product.efcazCLM ? i18n.locale = 'pt_clm' : i18n.locale = 'pt_sign'
    }

    async _iniciarKeycloak() {
        try {
            this.createAdapter()
            await this.startConnection()
            this.setCredentials()
            this.setRefreshToken()
            this.setDataInApplicationState()
        } catch (error) {
            throw new Error('Erro ao iniciar KeycloakAdapter: ' + error)
        }
    }

    createAdapter() {
        this.configuration = {
            url: '/auth',
            realm: 'az',
            clientId: 'efcaz-clm-app'
        }

        this.keycloakAdapter = Keycloak(this.configuration)
    }

    async startConnection() {
        await this.keycloakAdapter.init({ onLoad: 'login-required' })
    }

    async setCredentials() {
        const authorization = `Bearer ${this.keycloakAdapter.token}`
        axios.defaults.headers.common['Authorization'] = authorization
        await store.commit(mutationTypes.LOGIN.SET_AUTHORIZATION_TOKEN, authorization)
    }

    setRefreshToken() {
        setInterval(async () => {
            const refreshed = await this.keycloakAdapter.updateToken(15)
            if (refreshed) {
                await this.setCredentials()
            }
        }, 10000)
    }

    setDataInApplicationState() {
        store.state.loki.product.logoutUrl = this.createLogoutUrl()
        store.state.loki.product.profileUrl = this.createProfileURL()
    }

    createLogoutUrl() {
        return '/hal/logout'
    }

    createProfileURL() {
        return `${this.configuration.url}/realms/${this.configuration.realm}/account`
    }
}

export default new AppBuilder()
