<template>
   <v-card-text class="co-dialog-cancelar-plano">
        <div class="text-left">
            <div class="subtitulo">Que pena, você realmente deseja cancelar seu plano?</div>
            <div class="subtitulo">Nos ajude a melhorar, informe o motivo do cancelamento:</div>
        </div>

        <v-form ref="form" class="mx-4">
            <v-radio-group v-model="motivo.cancelamento" column mandatory class="mt-2" :disabled="loading">
                <v-radio v-for="motivo in motivosCancelamento" color="primary" :value="motivo" :label="motivo"/>
            </v-radio-group>

            <v-textarea
                label="Motivo Cancelamento"
                placeholder="Motivo Cancelamento"
                rows="2"
                outlined
                counter="250"
                auto-grow
                :disabled="!ehMotivoOutros || loading"
                :rules="rulesTextArea()"
                v-model="motivo.outros"
                validate-on-blur
            />
        </v-form>

        <v-alert dense text type="info" color="primary lighten-1">
            <v-row align="center" no-gutters>
                <v-col class="grow" style="font-size: 12px">
                    Hey, Talvez nosso time de especialistas consiga solucionar seu problema!
                </v-col>

                <v-col class="shrink">
                    <v-btn color="green" outlined x-small @click="chamarWhats">
                        Falar pelo WhatsApp
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-card-text>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'MensagemPlanoRecorrente',
    props: {
        loading: {
            type: Boolean,
            default:false
        },
        motivosCancelamento: {
            type: Array,
            required: true
        },
        motivo: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState(['login', 'contatoComercial']),
        ehMotivoOutros() {
            return this.motivo.cancelamento === "Outros (Descreva)"
        }
    },
    methods: {
        async chamarWhats() {
            let emailcadastradosistema = this.login.usuarioOrganizacao.usuarioEmail
            let nomeUsuario = this.login.usuarioOrganizacao.usuarioNome.replace(' ', '%20%20')
            let motivoCancelamento = ''
            if (this.ehMotivoOutros && await this.validarFormulario()) {
                motivoCancelamento = this.motivo.outros.replace(' ', '%20%20')
            } else {
                motivoCancelamento = this.motivo.cancelamento.replace(' ', '%20%20')
            }
            window.open(`https://api.whatsapp.com/send?phone=${this.contatoComercial.telefone}&text=Ol%C3%A1,%20sou%20${nomeUsuario},%20%20${emailcadastradosistema}%20e%20estou%20pensando%20em%20cancelar%20meu%20plano%20na%20BeSign%20porque:%20${motivoCancelamento}.%20Pode%20me%20ajudar?`, '_blank')
        },
        rulesTextArea() {
            if (this.ehMotivoOutros) {
                return [v => v.length <= 250 || 'Máximo 250 caracteres', v => v.length >= 5 || 'Mínimo 5 caracteres']
            } else {
                return []
            }
        },
        async validarFormulario() {
            return await this.$refs.form.validate()
        }
    }
}
</script>