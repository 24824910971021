<template>
    <v-dialog :value="dialog" width="650" persistent>
        <v-card class="co-dialog-notificacao">
            <v-card-title class="primary title lighten-1 white--text">
                <div class="titulo">Bem Vindo!!</div>
            </v-card-title>

            <v-card-text class="mt-3 descricao pb-0">
                <div v-if="stepper === 1">
                    <p>
                        Olá {{ organizacao.nome }}, você está a um passo de iniciar o envio dos seus documentos para assinatura.
                        Para aperfeiçoar sua jornada queremos conhecer um pouco mais sobre você e sua empresa.
                    </p>

                    <v-select
                        class="required-field mt-2 pt-0"
                        v-model="usuarioMarketing.motivoUso"
                        :items="motivosUso"
                        :error-messages="errors.collect('Motivo Uso')"
                        label="Qual o motivo do uso?"
                        name="Motivo Uso"
                        color="primary"
                        v-validate="'required'"
                        dense
                        outlined
                    />

                    <v-select
                        class="required-field mt-0 pt-0"
                        v-model="usuarioMarketing.segmento"
                        :items="segmentosPJ"
                        :error-messages="errors.collect('Segmento')"
                        label="Qual o segmento da sua empresa?"
                        name="Segmento"
                        color="primary"
                        v-validate="'required'"
                        dense
                        outlined
                    />
                </div>

                <div v-else>
                    <p>
                        Só mais esse passo e você já poderá usar a plataforma.
                        Caso deseje alterar essa configuração de notificação, acesse o Menu "Organização" dentro da opção de "Configurações" na lateral esquerda do sistema.
                    </p>

                    <v-divider/>

                    <v-switch v-model="organizacao.emailMarketing" label="Aceito receber notícias e promoções da Besign."/>
                </div>
            </v-card-text>

            <span v-if="ultimoPasso" class=" link-politica d-flex justify-center">
                Ao clicar em confirmar, você concorda com a nossa
                <a :href="produtoPolitica" target="__blank" class="politica ml-1">Política de Privacidade</a>
            </span>

            <v-card-actions class="mx-2 pb-4">
                <v-btn class="pa-4" color="background-200" elevation="0" @click="deslogar">Cancelar</v-btn>
                <v-btn v-if="ultimoPasso" class="pa-4" color="background-200" elevation="0" @click="voltar">Voltar</v-btn>
                <v-spacer/>
                <v-btn class="pa-4 white--text" color="var(--primary)" elevation="0" :disabled="botaoDesabilitado" @click="continuar">{{ultimoPasso ? 'Confirmar' : 'Continuar'}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {actionTypes} from '@/commons/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'DialogNovaOrganizacao',
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        ehPasta: {
            type: Boolean,
            default: false
        },
        usuarios: {
            type: Array
        }
    },
    data() {
        return {
            aceiteTermosUso: false,
            botaoDesabilitado: false,
            organizacao: {
                nome: '',
                tipoPessoa: 'FISICA',
                cnpjCPF: '',
                email: '',
                telefone: '',
                recebeEmails: true,
                emailMarketing: true
            },
            stepper: 1,
            usuarioMarketing: {
                motivoUso: '',
                segmento: ''
            },
            motivosUso: [
                'Quero Contratar',
                'Avaliando para minha empresa',
                'Quero um Certificado Digital',
                'Assinar Documento para Empréstimo',
                'Assinar contrato de imobiliária',
                'Já uso uma solução de assinatura eletrônica',
                'Quero passar a assinar meus contratos físicos para digitais',
                'Quero entender sobre a API',
                'Quero assinar documentos com certificado digital',
                'Só preciso assinar um documento'
            ],
            segmentosPJ: [
                'Agro',
                'Autônomo',
                'Comércio/E-Comerce/Distribuição',
                'Consultoria',
                'Contabilidade',
                'Construção',
                'Educação Privada',
                'Estudante',
                'Financeiro e Bancário',
                'Governo, fundação pública e universidades públicas',
                'Imobiliário',
                'Jurídico',
                'Marketing e Publicidade',
                'Prestador de Serviço',
                'RH e Recrutamento',
                'Saúde',
                'Seguros e Previdência',
                'Tecnologia, Software e Cloud',
                'Telecomunicações e Mídia',
                'Terceiro Setor',
                'Turismo e Lazer',
                'Varejo',
                'Outro'
            ]
        }
    },
    computed: {
        ...mapGetters(['ehEfcazCLM']),
        produtoPolitica() {
            return this.ehEfcazCLM ? 'https://www.efcaz.com.br/lgpd' : 'https://www.besign.com.br/politica-de-privacidade'
        },
        ultimoPasso() {
            return this.stepper === 2
        }
    },
    async created() {
        await this.buscarDadosOrganizacaoByUsuarioLogado()
    },
    methods: {
        async atualizarDependencias() {
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_ORGANIZACAO_PLANO)
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_VINCULO_USUARIO_ORGANIZACAO)
            await this.$store.dispatch(actionTypes.CONFIGURACAO.BUSCAR_ORGANIZACAO_CONFIGURACOES)
            await this.$store.dispatch(actionTypes.LOGIN.BUSCAR_FUNCIONALIDADES_ORGANIZACAO)
        },
        async buscarDadosOrganizacaoByUsuarioLogado() {
            const dadosOrganizacao = this.$store.state.organizacao
            this.organizacao.nome = dadosOrganizacao.nome
            this.organizacao.cnpjCPF = dadosOrganizacao.cpf
            this.organizacao.email = dadosOrganizacao.email
            this.organizacao.telefone = dadosOrganizacao.telefone
        },
        async cadastrarOrganizacao() {
            this.$emit('aceitou-termos')

            await this.$store.dispatch(actionTypes.INSERIR_ORGANIZACAO, this.organizacao)
            await this.atualizarDependencias()
        },
        async continuar() {
            if (this.stepper === 1) {
                const valido = await this.$validator._base.validateAll()
                if (!valido) {
                    return
                }
                this.stepper = 2
            } else {
                try {
                    this.botaoDesabilitado = true
                    this.habilitarLoadingGlobal()

                    await this.inserirUsuarioMarketing()
                    await this.cadastrarOrganizacao()

                    this.desabilitarLoadingGlobal()
                } catch (e) {
                    this.desabilitarLoadingGlobal()
                    this.botaoDesabilitado = false
                }
            }
        },
        deslogar() {
            window.location.assign('/efcaz-clm/hal/logout')
        },
        async inserirUsuarioMarketing() {
            await this.$store.dispatch(actionTypes.USUARIO_MARKETING.INSERIR_USUARIO_MARKETING, this.usuarioMarketing)
        },
        voltar() {
            this.stepper = 1
        }
    }
}
</script>

<style lang="stylus">
.co-dialog-notificacao
    .titulo
        width 80%

    .descricao
        min-height 191px

    .link-politica
        height 23px
        color var(--text-color-200)
        font-size 14px

        .politica
            color --var(primary)
            font-size 15px

    .co-lista-acesso
        width 350px
        display block
        color #777

        .subtitulo-lista
            padding 0 5px 5px 5px
            color #bbb
            font-size 11px
            border-bottom 1px solid #ddd

        .titulo-lista
            padding 5px 5px 0 5px
            font-weight bold

        .item-lista
            padding 5px
            display flex
            justify-content space-between

    .co-lista-acesso-admin
        display block
        color #777
        padding 10px 20px

        .subtitulo-lista
            padding 0 0 5px 0
            color #bbb
            font-size 11px
            border-bottom 1px solid #ddd

        .titulo-lista
            padding 5px 0 0 0
            font-weight bold

        .item-lista
            padding 5px 0
            display flex
            justify-content space-between
</style>
